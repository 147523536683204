/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { create } from '../fn/inventory-publication-controller/create';
import { Create$Params } from '../fn/inventory-publication-controller/create';
import { delete$ } from '../fn/inventory-publication-controller/delete';
import { Delete$Params } from '../fn/inventory-publication-controller/delete';
import { get } from '../fn/inventory-publication-controller/get';
import { Get$Params } from '../fn/inventory-publication-controller/get';
import { Count as LoopbackCount } from '../models/loopback/count';
import { patch } from '../fn/inventory-publication-controller/patch';
import { Patch$Params } from '../fn/inventory-publication-controller/patch';
import { Publication } from '../models/publication';

@Injectable({ providedIn: 'root' })
export class InventoryPublicationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `inventoryPublicationControllerGet()` */
  static readonly InventoryPublicationControllerGetPath = '/inventories/{id}/publication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `get()` instead.
   *
   * This method doesn't expect any request body.
   */
  get$Response(params: Get$Params, context?: HttpContext): Observable<StrictHttpResponse<Publication>> {
    return get(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `get$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  get(params: Get$Params, context?: HttpContext): Observable<Publication> {
    return this.get$Response(params, context).pipe(
      map((r: StrictHttpResponse<Publication>): Publication => r.body)
    );
  }

  /** Path part for operation `inventoryPublicationControllerCreate()` */
  static readonly InventoryPublicationControllerCreatePath = '/inventories/{id}/publication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Publication>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<Publication> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Publication>): Publication => r.body)
    );
  }

  /** Path part for operation `inventoryPublicationControllerDelete()` */
  static readonly InventoryPublicationControllerDeletePath = '/inventories/{id}/publication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

  /** Path part for operation `inventoryPublicationControllerPatch()` */
  static readonly InventoryPublicationControllerPatchPath = '/inventories/{id}/publication';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patch$Response(params: Patch$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return patch(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patch(params: Patch$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.patch$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

}
