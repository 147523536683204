/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AuthRoleWithRelations } from '../models/auth-role-with-relations';
import { find } from '../fn/auth-role-controller/find';
import { Find$Params } from '../fn/auth-role-controller/find';
import { findById } from '../fn/auth-role-controller/find-by-id';
import { FindById$Params } from '../fn/auth-role-controller/find-by-id';

@Injectable({ providedIn: 'root' })
export class AuthRoleControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authRoleControllerFindById()` */
  static readonly AuthRoleControllerFindByIdPath = '/auth-roles/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: FindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthRoleWithRelations>> {
    return findById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: FindById$Params, context?: HttpContext): Observable<AuthRoleWithRelations> {
    return this.findById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthRoleWithRelations>): AuthRoleWithRelations => r.body)
    );
  }

  /** Path part for operation `authRoleControllerFind()` */
  static readonly AuthRoleControllerFindPath = '/auth-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: Find$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AuthRoleWithRelations>>> {
    return find(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: Find$Params, context?: HttpContext): Observable<Array<AuthRoleWithRelations>> {
    return this.find$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuthRoleWithRelations>>): Array<AuthRoleWithRelations> => r.body)
    );
  }

}
