/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { importAuthorsFromCsv } from '../fn/admin-author-controller/import-authors-from-csv';
import { ImportAuthorsFromCsv$Params } from '../fn/admin-author-controller/import-authors-from-csv';

@Injectable({ providedIn: 'root' })
export class AdminAuthorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authorControllerImportAuthorsFromCsv()` */
  static readonly AuthorControllerImportAuthorsFromCsvPath = '/admin/authors/import';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importAuthorsFromCsv()` instead.
   *
   * This method doesn't expect any request body.
   */
  importAuthorsFromCsv$Response(params?: ImportAuthorsFromCsv$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return importAuthorsFromCsv(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importAuthorsFromCsv$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  importAuthorsFromCsv(params?: ImportAuthorsFromCsv$Params, context?: HttpContext): Observable<any> {
    return this.importAuthorsFromCsv$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
