/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { importFromCSV } from '../fn/import-controller/import-from-csv';
import { ImportFromCSV$Params } from '../fn/import-controller/import-from-csv';

@Injectable({ providedIn: 'root' })
export class ImportControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `importControllerImportFromCsv()` */
  static readonly ImportControllerImportFromCsvPath = '/import/inventory/{id}/from-csv';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `importFromCSV()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importFromCSV$Response(params: ImportFromCSV$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
    return importFromCSV(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `importFromCSV$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  importFromCSV(params: ImportFromCSV$Params, context?: HttpContext): Observable<{
}> {
    return this.importFromCSV$Response(params, context).pipe(
      map((r: StrictHttpResponse<{
}>): {
} => r.body)
    );
  }

}
