/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createTag } from '../fn/tag-controller/create-tag';
import { CreateTag$Params } from '../fn/tag-controller/create-tag';
import { deleteTagById } from '../fn/tag-controller/delete-tag-by-id';
import { DeleteTagById$Params } from '../fn/tag-controller/delete-tag-by-id';
import { findTagByIdAdmin } from '../fn/tag-controller/find-tag-by-id-admin';
import { FindTagByIdAdmin$Params } from '../fn/tag-controller/find-tag-by-id-admin';
import { findTagsAdmin } from '../fn/tag-controller/find-tags-admin';
import { FindTagsAdmin$Params } from '../fn/tag-controller/find-tags-admin';
import { Tag } from '../models/tag';
import { updateById } from '../fn/tag-controller/update-by-id';
import { UpdateById$Params } from '../fn/tag-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class TagControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tagControllerFindTagByIdAdmin()` */
  static readonly TagControllerFindTagByIdAdminPath = '/admin/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTagByIdAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagByIdAdmin$Response(params: FindTagByIdAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Tag>> {
    return findTagByIdAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findTagByIdAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagByIdAdmin(params: FindTagByIdAdmin$Params, context?: HttpContext): Observable<Tag> {
    return this.findTagByIdAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tag>): Tag => r.body)
    );
  }

  /** Path part for operation `tagControllerDeleteTagById()` */
  static readonly TagControllerDeleteTagByIdPath = '/admin/tags/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTagById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagById$Response(params: DeleteTagById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTagById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTagById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagById(params: DeleteTagById$Params, context?: HttpContext): Observable<void> {
    return this.deleteTagById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `tagControllerUpdateById()` */
  static readonly TagControllerUpdateByIdPath = '/admin/tags/{id}';

  /**
   * Update a tag gby its ID with partial data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<Tag>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a tag gby its ID with partial data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<Tag> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tag>): Tag => r.body)
    );
  }

  /** Path part for operation `tagControllerFindTagsAdmin()` */
  static readonly TagControllerFindTagsAdminPath = '/admin/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findTagsAdmin()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagsAdmin$Response(params: FindTagsAdmin$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Tag>>> {
    return findTagsAdmin(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findTagsAdmin$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findTagsAdmin(params: FindTagsAdmin$Params, context?: HttpContext): Observable<Array<Tag>> {
    return this.findTagsAdmin$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Tag>>): Array<Tag> => r.body)
    );
  }

  /** Path part for operation `tagControllerCreateTag()` */
  static readonly TagControllerCreateTagPath = '/admin/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTag()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag$Response(params?: CreateTag$Params, context?: HttpContext): Observable<StrictHttpResponse<Tag>> {
    return createTag(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTag$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTag(params?: CreateTag$Params, context?: HttpContext): Observable<Tag> {
    return this.createTag$Response(params, context).pipe(
      map((r: StrictHttpResponse<Tag>): Tag => r.body)
    );
  }

}
