/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteById } from '../fn/admin-exhibitions-controller/admin-delete-by-id';
import { AdminDeleteById$Params } from '../fn/admin-exhibitions-controller/admin-delete-by-id';
import { adminFind } from '../fn/admin-exhibitions-controller/admin-find';
import { AdminFind$Params } from '../fn/admin-exhibitions-controller/admin-find';
import { adminFindById } from '../fn/admin-exhibitions-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-exhibitions-controller/admin-find-by-id';
import { adminGetListForExhibition } from '../fn/admin-exhibitions-controller/admin-get-list-for-exhibition';
import { AdminGetListForExhibition$Params } from '../fn/admin-exhibitions-controller/admin-get-list-for-exhibition';
import { approve } from '../fn/admin-exhibitions-controller/approve';
import { Approve$Params } from '../fn/admin-exhibitions-controller/approve';
import { create } from '../fn/admin-exhibitions-controller/create';
import { Create$Params } from '../fn/admin-exhibitions-controller/create';
import { Exhibition } from '../models/exhibition';
import { ExhibitionWithRelations } from '../models/exhibition-with-relations';
import { getList } from '../fn/admin-exhibitions-controller/get-list';
import { GetList$Params } from '../fn/admin-exhibitions-controller/get-list';
import { ItemExhibitionWithRelations } from '../models/item-exhibition-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';
import { replaceById } from '../fn/admin-exhibitions-controller/replace-by-id';
import { ReplaceById$Params } from '../fn/admin-exhibitions-controller/replace-by-id';
import { updateAll } from '../fn/admin-exhibitions-controller/update-all';
import { UpdateAll$Params } from '../fn/admin-exhibitions-controller/update-all';
import { updateById } from '../fn/admin-exhibitions-controller/update-by-id';
import { UpdateById$Params } from '../fn/admin-exhibitions-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class AdminExhibitionsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `exhibitionControllerGetList()` */
  static readonly ExhibitionControllerGetListPath = '/admin/exhibitions/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: GetList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExhibitionWithRelations>>> {
    return getList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: GetList$Params, context?: HttpContext): Observable<Array<ExhibitionWithRelations>> {
    return this.getList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExhibitionWithRelations>>): Array<ExhibitionWithRelations> => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerApprove()` */
  static readonly ExhibitionControllerApprovePath = '/admin/exhibitions/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approve()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve$Response(params: Approve$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return approve(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve(params: Approve$Params, context?: HttpContext): Observable<any> {
    return this.approve$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemExhibitionControllerAdminGetListForExhibition()` */
  static readonly ItemExhibitionControllerAdminGetListForExhibitionPath = '/admin/exhibitions/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetListForExhibition()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForExhibition$Response(params: AdminGetListForExhibition$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemExhibitionWithRelations>>> {
    return adminGetListForExhibition(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetListForExhibition$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForExhibition(params: AdminGetListForExhibition$Params, context?: HttpContext): Observable<Array<ItemExhibitionWithRelations>> {
    return this.adminGetListForExhibition$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemExhibitionWithRelations>>): Array<ItemExhibitionWithRelations> => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerAdminFindById()` */
  static readonly ExhibitionControllerAdminFindByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ExhibitionWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<ExhibitionWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ExhibitionWithRelations>): ExhibitionWithRelations => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerReplaceById()` */
  static readonly ExhibitionControllerReplaceByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: ReplaceById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return replaceById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: ReplaceById$Params, context?: HttpContext): Observable<any> {
    return this.replaceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerAdminDeleteById()` */
  static readonly ExhibitionControllerAdminDeleteByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: AdminDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: AdminDeleteById$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerUpdateById()` */
  static readonly ExhibitionControllerUpdateByIdPath = '/admin/exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<any> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerAdminFind()` */
  static readonly ExhibitionControllerAdminFindPath = '/admin/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: AdminFind$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ExhibitionWithRelations>>> {
    return adminFind(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: AdminFind$Params, context?: HttpContext): Observable<Array<ExhibitionWithRelations>> {
    return this.adminFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ExhibitionWithRelations>>): Array<ExhibitionWithRelations> => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerCreate()` */
  static readonly ExhibitionControllerCreatePath = '/admin/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Exhibition>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<Exhibition> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Exhibition>): Exhibition => r.body)
    );
  }

  /** Path part for operation `exhibitionControllerUpdateAll()` */
  static readonly ExhibitionControllerUpdateAllPath = '/admin/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: UpdateAll$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return updateAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: UpdateAll$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.updateAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

}
