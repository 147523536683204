/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminFindTagGroupById } from '../fn/tag-group-controller/admin-find-tag-group-by-id';
import { AdminFindTagGroupById$Params } from '../fn/tag-group-controller/admin-find-tag-group-by-id';
import { createTagGroup } from '../fn/tag-group-controller/create-tag-group';
import { CreateTagGroup$Params } from '../fn/tag-group-controller/create-tag-group';
import { deleteTagGroupById } from '../fn/tag-group-controller/delete-tag-group-by-id';
import { DeleteTagGroupById$Params } from '../fn/tag-group-controller/delete-tag-group-by-id';
import { duplicateTagGroupsToInventory } from '../fn/tag-group-controller/duplicate-tag-groups-to-inventory';
import { DuplicateTagGroupsToInventory$Params } from '../fn/tag-group-controller/duplicate-tag-groups-to-inventory';
import { findAll } from '../fn/tag-group-controller/find-all';
import { FindAll$Params } from '../fn/tag-group-controller/find-all';
import { TagGroup } from '../models/tag-group';
import { updateById } from '../fn/tag-group-controller/update-by-id';
import { UpdateById$Params } from '../fn/tag-group-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class TagGroupControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `tagGroupControllerDuplicateTagGroupsToInventory()` */
  static readonly TagGroupControllerDuplicateTagGroupsToInventoryPath = '/admin/tag-groups/duplicate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `duplicateTagGroupsToInventory()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateTagGroupsToInventory$Response(params?: DuplicateTagGroupsToInventory$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagGroup>>> {
    return duplicateTagGroupsToInventory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `duplicateTagGroupsToInventory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  duplicateTagGroupsToInventory(params?: DuplicateTagGroupsToInventory$Params, context?: HttpContext): Observable<Array<TagGroup>> {
    return this.duplicateTagGroupsToInventory$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagGroup>>): Array<TagGroup> => r.body)
    );
  }

  /** Path part for operation `tagGroupControllerAdminFindTagGroupById()` */
  static readonly TagGroupControllerAdminFindTagGroupByIdPath = '/admin/tag-groups/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindTagGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindTagGroupById$Response(params: AdminFindTagGroupById$Params, context?: HttpContext): Observable<StrictHttpResponse<TagGroup>> {
    return adminFindTagGroupById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindTagGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindTagGroupById(params: AdminFindTagGroupById$Params, context?: HttpContext): Observable<TagGroup> {
    return this.adminFindTagGroupById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagGroup>): TagGroup => r.body)
    );
  }

  /** Path part for operation `tagGroupControllerDeleteTagGroupById()` */
  static readonly TagGroupControllerDeleteTagGroupByIdPath = '/admin/tag-groups/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteTagGroupById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagGroupById$Response(params: DeleteTagGroupById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteTagGroupById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteTagGroupById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteTagGroupById(params: DeleteTagGroupById$Params, context?: HttpContext): Observable<void> {
    return this.deleteTagGroupById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `tagGroupControllerUpdateById()` */
  static readonly TagGroupControllerUpdateByIdPath = '/admin/tag-groups/{id}';

  /**
   * Update a tag group by its ID with partial data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<TagGroup>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Update a tag group by its ID with partial data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<TagGroup> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagGroup>): TagGroup => r.body)
    );
  }

  /** Path part for operation `tagGroupControllerFindAll()` */
  static readonly TagGroupControllerFindAllPath = '/admin/tag-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(params?: FindAll$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagGroup>>> {
    return findAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(params?: FindAll$Params, context?: HttpContext): Observable<Array<TagGroup>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagGroup>>): Array<TagGroup> => r.body)
    );
  }

  /** Path part for operation `tagGroupControllerCreateTagGroup()` */
  static readonly TagGroupControllerCreateTagGroupPath = '/admin/tag-groups';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createTagGroup()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTagGroup$Response(params?: CreateTagGroup$Params, context?: HttpContext): Observable<StrictHttpResponse<TagGroup>> {
    return createTagGroup(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createTagGroup$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createTagGroup(params?: CreateTagGroup$Params, context?: HttpContext): Observable<TagGroup> {
    return this.createTagGroup$Response(params, context).pipe(
      map((r: StrictHttpResponse<TagGroup>): TagGroup => r.body)
    );
  }

}
