/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminFind } from '../fn/location-controller/admin-find';
import { AdminFind$Params } from '../fn/location-controller/admin-find';
import { adminFindById } from '../fn/location-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/location-controller/admin-find-by-id';
import { create } from '../fn/location-controller/create';
import { Create$Params } from '../fn/location-controller/create';
import { deleteLocationById } from '../fn/location-controller/delete-location-by-id';
import { DeleteLocationById$Params } from '../fn/location-controller/delete-location-by-id';
import { Location } from '../models/location';
import { LocationWithRelations } from '../models/location-with-relations';
import { updateById } from '../fn/location-controller/update-by-id';
import { UpdateById$Params } from '../fn/location-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class LocationControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `locationControllerAdminFindById()` */
  static readonly LocationControllerAdminFindByIdPath = '/admin/locations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<LocationWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<LocationWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<LocationWithRelations>): LocationWithRelations => r.body)
    );
  }

  /** Path part for operation `locationControllerDeleteLocationById()` */
  static readonly LocationControllerDeleteLocationByIdPath = '/admin/locations/{id}';

  /**
   * Delete location by its ID
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteLocationById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLocationById$Response(params: DeleteLocationById$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteLocationById(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete location by its ID
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteLocationById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteLocationById(params: DeleteLocationById$Params, context?: HttpContext): Observable<void> {
    return this.deleteLocationById$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `locationControllerUpdateById()` */
  static readonly LocationControllerUpdateByIdPath = '/admin/locations/{id}';

  /**
   * Update location by its ID with partial data
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<Location>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Update location by its ID with partial data
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<Location> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<Location>): Location => r.body)
    );
  }

  /** Path part for operation `locationControllerAdminFind()` */
  static readonly LocationControllerAdminFindPath = '/admin/locations';

  /**
   * Get locations
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: AdminFind$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocationWithRelations>>> {
    return adminFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Get locations
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: AdminFind$Params, context?: HttpContext): Observable<Array<LocationWithRelations>> {
    return this.adminFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocationWithRelations>>): Array<LocationWithRelations> => r.body)
    );
  }

  /** Path part for operation `locationControllerCreate()` */
  static readonly LocationControllerCreatePath = '/admin/locations';

  /**
   * Create a new location
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Location>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Create a new location
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<Location> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Location>): Location => r.body)
    );
  }

}
