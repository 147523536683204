/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContactTypeWithRelations } from '../models/contact-type-with-relations';
import { getContactTypeList } from '../fn/contact-type-controller/get-contact-type-list';
import { GetContactTypeList$Params } from '../fn/contact-type-controller/get-contact-type-list';

@Injectable({ providedIn: 'root' })
export class ContactTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactTypeControllerGetContactTypeList()` */
  static readonly ContactTypeControllerGetContactTypeListPath = '/contact-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContactTypeList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContactTypeList$Response(params?: GetContactTypeList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactTypeWithRelations>>> {
    return getContactTypeList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContactTypeList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContactTypeList(params?: GetContactTypeList$Params, context?: HttpContext): Observable<Array<ContactTypeWithRelations>> {
    return this.getContactTypeList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactTypeWithRelations>>): Array<ContactTypeWithRelations> => r.body)
    );
  }

}
