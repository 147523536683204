/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminFindById } from '../fn/admin-series-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-series-controller/admin-find-by-id';
import { create } from '../fn/admin-series-controller/create';
import { Create$Params } from '../fn/admin-series-controller/create';
import { delete$ } from '../fn/admin-series-controller/delete';
import { Delete$Params } from '../fn/admin-series-controller/delete';
import { SeriesPartialExcludingItemsLangWithRelations } from '../models/series-partial-excluding-items-lang-with-relations';
import { SeriesWithRelations } from '../models/series-with-relations';
import { updateById } from '../fn/admin-series-controller/update-by-id';
import { UpdateById$Params } from '../fn/admin-series-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class AdminSeriesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `seriesControllerAdminFindById()` */
  static readonly SeriesControllerAdminFindByIdPath = '/admin/series/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SeriesWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<SeriesWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeriesWithRelations>): SeriesWithRelations => r.body)
    );
  }

  /** Path part for operation `seriesControllerDelete()` */
  static readonly SeriesControllerDeletePath = '/admin/series/{id}';

  /**
   * Delete series by ID. Ensures that no items are associated with the series before deletion.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `delete()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete$Response(params: Delete$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return delete$(this.http, this.rootUrl, params, context);
  }

  /**
   * Delete series by ID. Ensures that no items are associated with the series before deletion.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `delete$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  delete(params: Delete$Params, context?: HttpContext): Observable<boolean> {
    return this.delete$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `seriesControllerUpdateById()` */
  static readonly SeriesControllerUpdateByIdPath = '/admin/series/{id}';

  /**
   * Update series by ID, including language-specific details.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<SeriesPartialExcludingItemsLangWithRelations>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * Update series by ID, including language-specific details.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<SeriesPartialExcludingItemsLangWithRelations> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeriesPartialExcludingItemsLangWithRelations>): SeriesPartialExcludingItemsLangWithRelations => r.body)
    );
  }

  /** Path part for operation `seriesControllerCreate()` */
  static readonly SeriesControllerCreatePath = '/admin/series';

  /**
   * Creates a new series entity.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<SeriesWithRelations>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * Creates a new series entity.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<SeriesWithRelations> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeriesWithRelations>): SeriesWithRelations => r.body)
    );
  }

}
