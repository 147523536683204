/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getSignatureTypesList } from '../fn/signature-type-controller/get-signature-types-list';
import { GetSignatureTypesList$Params } from '../fn/signature-type-controller/get-signature-types-list';
import { SignatureTypeWithRelations } from '../models/signature-type-with-relations';

@Injectable({ providedIn: 'root' })
export class SignatureTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `signatureTypeControllerGetSignatureTypesList()` */
  static readonly SignatureTypeControllerGetSignatureTypesListPath = '/signature-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getSignatureTypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignatureTypesList$Response(params?: GetSignatureTypesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SignatureTypeWithRelations>>> {
    return getSignatureTypesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getSignatureTypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getSignatureTypesList(params?: GetSignatureTypesList$Params, context?: HttpContext): Observable<Array<SignatureTypeWithRelations>> {
    return this.getSignatureTypesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SignatureTypeWithRelations>>): Array<SignatureTypeWithRelations> => r.body)
    );
  }

}
