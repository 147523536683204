/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addInventoryUser } from '../fn/inventory-controller/add-inventory-user';
import { AddInventoryUser$Params } from '../fn/inventory-controller/add-inventory-user';
import { AuthorWithRelations } from '../models/author-with-relations';
import { changeInventoryUserRole } from '../fn/inventory-controller/change-inventory-user-role';
import { ChangeInventoryUserRole$Params } from '../fn/inventory-controller/change-inventory-user-role';
import { ContactRole } from '../models/contact-role';
import { ContactWithRelations } from '../models/contact-with-relations';
import { count } from '../fn/inventory-controller/count';
import { Count$Params } from '../fn/inventory-controller/count';
import { create } from '../fn/inventory-controller/create';
import { Create$Params } from '../fn/inventory-controller/create';
import { deleteById } from '../fn/inventory-controller/delete-by-id';
import { DeleteById$Params } from '../fn/inventory-controller/delete-by-id';
import { deleteInventoryUserById } from '../fn/inventory-controller/delete-inventory-user-by-id';
import { DeleteInventoryUserById$Params } from '../fn/inventory-controller/delete-inventory-user-by-id';
import { find } from '../fn/inventory-controller/find';
import { Find$Params } from '../fn/inventory-controller/find';
import { findById } from '../fn/inventory-controller/find-by-id';
import { FindById$Params } from '../fn/inventory-controller/find-by-id';
import { getInventoryAuthors } from '../fn/inventory-controller/get-inventory-authors';
import { GetInventoryAuthors$Params } from '../fn/inventory-controller/get-inventory-authors';
import { getInventoryContactRoles } from '../fn/inventory-controller/get-inventory-contact-roles';
import { GetInventoryContactRoles$Params } from '../fn/inventory-controller/get-inventory-contact-roles';
import { getInventoryContacts } from '../fn/inventory-controller/get-inventory-contacts';
import { GetInventoryContacts$Params } from '../fn/inventory-controller/get-inventory-contacts';
import { getInventoryLocations } from '../fn/inventory-controller/get-inventory-locations';
import { GetInventoryLocations$Params } from '../fn/inventory-controller/get-inventory-locations';
import { getInventoryRoles } from '../fn/inventory-controller/get-inventory-roles';
import { GetInventoryRoles$Params } from '../fn/inventory-controller/get-inventory-roles';
import { getInventoryTags } from '../fn/inventory-controller/get-inventory-tags';
import { GetInventoryTags$Params } from '../fn/inventory-controller/get-inventory-tags';
import { getInventoryTagsFlat } from '../fn/inventory-controller/get-inventory-tags-flat';
import { GetInventoryTagsFlat$Params } from '../fn/inventory-controller/get-inventory-tags-flat';
import { getInventoryUsers } from '../fn/inventory-controller/get-inventory-users';
import { GetInventoryUsers$Params } from '../fn/inventory-controller/get-inventory-users';
import { getInventoryWithRelations } from '../fn/inventory-controller/get-inventory-with-relations';
import { GetInventoryWithRelations$Params } from '../fn/inventory-controller/get-inventory-with-relations';
import { Inventory } from '../models/inventory';
import { InventoryRole } from '../models/inventory-role';
import { InventoryUser } from '../models/inventory-user';
import { InventoryWithRelations } from '../models/inventory-with-relations';
import { LocationWithRelations } from '../models/location-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';
import { replaceById } from '../fn/inventory-controller/replace-by-id';
import { ReplaceById$Params } from '../fn/inventory-controller/replace-by-id';
import { Tag } from '../models/tag';
import { TagGroupWithRelations } from '../models/tag-group-with-relations';
import { updateAll } from '../fn/inventory-controller/update-all';
import { UpdateAll$Params } from '../fn/inventory-controller/update-all';
import { updateById } from '../fn/inventory-controller/update-by-id';
import { UpdateById$Params } from '../fn/inventory-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class InventoryControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `inventoryControllerGetInventoryRoles()` */
  static readonly InventoryControllerGetInventoryRolesPath = '/admin/inventories/roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryRoles$Response(params?: GetInventoryRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InventoryRole>>> {
    return getInventoryRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryRoles(params?: GetInventoryRoles$Params, context?: HttpContext): Observable<Array<InventoryRole>> {
    return this.getInventoryRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InventoryRole>>): Array<InventoryRole> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerChangeInventoryUserRole()` */
  static readonly InventoryControllerChangeInventoryUserRolePath = '/admin/inventories/user/{id}/change';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `changeInventoryUserRole()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeInventoryUserRole$Response(params: ChangeInventoryUserRole$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryUser>> {
    return changeInventoryUserRole(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `changeInventoryUserRole$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  changeInventoryUserRole(params: ChangeInventoryUserRole$Params, context?: HttpContext): Observable<InventoryUser> {
    return this.changeInventoryUserRole$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryUser>): InventoryUser => r.body)
    );
  }

  /** Path part for operation `inventoryControllerDeleteInventoryUserById()` */
  static readonly InventoryControllerDeleteInventoryUserByIdPath = '/admin/inventories/user/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteInventoryUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInventoryUserById$Response(params: DeleteInventoryUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return deleteInventoryUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteInventoryUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteInventoryUserById(params: DeleteInventoryUserById$Params, context?: HttpContext): Observable<any> {
    return this.deleteInventoryUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryLocations()` */
  static readonly InventoryControllerGetInventoryLocationsPath = '/admin/inventories/{id}/locations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryLocations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryLocations$Response(params: GetInventoryLocations$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<LocationWithRelations>>> {
    return getInventoryLocations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryLocations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryLocations(params: GetInventoryLocations$Params, context?: HttpContext): Observable<Array<LocationWithRelations>> {
    return this.getInventoryLocations$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<LocationWithRelations>>): Array<LocationWithRelations> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryTagsFlat()` */
  static readonly InventoryControllerGetInventoryTagsFlatPath = '/admin/inventories/{id}/tags/flat';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryTagsFlat()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTagsFlat$Response(params: GetInventoryTagsFlat$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<Tag>>> {
    return getInventoryTagsFlat(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryTagsFlat$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTagsFlat(params: GetInventoryTagsFlat$Params, context?: HttpContext): Observable<Array<Tag>> {
    return this.getInventoryTagsFlat$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<Tag>>): Array<Tag> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryTags()` */
  static readonly InventoryControllerGetInventoryTagsPath = '/admin/inventories/{id}/tags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTags$Response(params: GetInventoryTags$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<TagGroupWithRelations>>> {
    return getInventoryTags(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryTags(params: GetInventoryTags$Params, context?: HttpContext): Observable<Array<TagGroupWithRelations>> {
    return this.getInventoryTags$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<TagGroupWithRelations>>): Array<TagGroupWithRelations> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryUsers()` */
  static readonly InventoryControllerGetInventoryUsersPath = '/admin/inventories/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryUsers$Response(params: GetInventoryUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InventoryUser>>> {
    return getInventoryUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryUsers(params: GetInventoryUsers$Params, context?: HttpContext): Observable<Array<InventoryUser>> {
    return this.getInventoryUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InventoryUser>>): Array<InventoryUser> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerAddInventoryUser()` */
  static readonly InventoryControllerAddInventoryUserPath = '/admin/inventories/{id}/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addInventoryUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  addInventoryUser$Response(params: AddInventoryUser$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryUser>> {
    return addInventoryUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addInventoryUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addInventoryUser(params: AddInventoryUser$Params, context?: HttpContext): Observable<InventoryUser> {
    return this.addInventoryUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryUser>): InventoryUser => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryAuthors()` */
  static readonly InventoryControllerGetInventoryAuthorsPath = '/inventories/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryAuthors()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryAuthors$Response(params: GetInventoryAuthors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AuthorWithRelations>>> {
    return getInventoryAuthors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryAuthors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryAuthors(params: GetInventoryAuthors$Params, context?: HttpContext): Observable<Array<AuthorWithRelations>> {
    return this.getInventoryAuthors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuthorWithRelations>>): Array<AuthorWithRelations> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryContactRoles()` */
  static readonly InventoryControllerGetInventoryContactRolesPath = '/inventories/contact-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryContactRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContactRoles$Response(params: GetInventoryContactRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactRole>>> {
    return getInventoryContactRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryContactRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContactRoles(params: GetInventoryContactRoles$Params, context?: HttpContext): Observable<Array<ContactRole>> {
    return this.getInventoryContactRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactRole>>): Array<ContactRole> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryContacts()` */
  static readonly InventoryControllerGetInventoryContactsPath = '/inventories/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContacts$Response(params: GetInventoryContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactWithRelations>>> {
    return getInventoryContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryContacts(params: GetInventoryContacts$Params, context?: HttpContext): Observable<Array<ContactWithRelations>> {
    return this.getInventoryContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactWithRelations>>): Array<ContactWithRelations> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerCount()` */
  static readonly InventoryControllerCountPath = '/inventories/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(params?: Count$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return count(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(params?: Count$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

  /** Path part for operation `inventoryControllerGetInventoryWithRelations()` */
  static readonly InventoryControllerGetInventoryWithRelationsPath = '/inventories/{id}/complete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryWithRelations()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryWithRelations$Response(params: GetInventoryWithRelations$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryWithRelations>> {
    return getInventoryWithRelations(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryWithRelations$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryWithRelations(params: GetInventoryWithRelations$Params, context?: HttpContext): Observable<InventoryWithRelations> {
    return this.getInventoryWithRelations$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryWithRelations>): InventoryWithRelations => r.body)
    );
  }

  /** Path part for operation `inventoryControllerFindById()` */
  static readonly InventoryControllerFindByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params: FindById$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryWithRelations>> {
    return findById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params: FindById$Params, context?: HttpContext): Observable<InventoryWithRelations> {
    return this.findById$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryWithRelations>): InventoryWithRelations => r.body)
    );
  }

  /** Path part for operation `inventoryControllerReplaceById()` */
  static readonly InventoryControllerReplaceByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: ReplaceById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return replaceById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: ReplaceById$Params, context?: HttpContext): Observable<any> {
    return this.replaceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `inventoryControllerDeleteById()` */
  static readonly InventoryControllerDeleteByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById$Response(params: DeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return deleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteById(params: DeleteById$Params, context?: HttpContext): Observable<any> {
    return this.deleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `inventoryControllerUpdateById()` */
  static readonly InventoryControllerUpdateByIdPath = '/inventories/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<any> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `inventoryControllerFind()` */
  static readonly InventoryControllerFindPath = '/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `find()` instead.
   *
   * This method doesn't expect any request body.
   */
  find$Response(params?: Find$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InventoryWithRelations>>> {
    return find(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `find$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  find(params?: Find$Params, context?: HttpContext): Observable<Array<InventoryWithRelations>> {
    return this.find$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InventoryWithRelations>>): Array<InventoryWithRelations> => r.body)
    );
  }

  /** Path part for operation `inventoryControllerCreate()` */
  static readonly InventoryControllerCreatePath = '/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Inventory>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<Inventory> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Inventory>): Inventory => r.body)
    );
  }

  /** Path part for operation `inventoryControllerUpdateAll()` */
  static readonly InventoryControllerUpdateAllPath = '/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: UpdateAll$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return updateAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: UpdateAll$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.updateAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

}
