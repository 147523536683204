/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { createNewInventory } from '../fn/admin-controller/create-new-inventory';
import { CreateNewInventory$Params } from '../fn/admin-controller/create-new-inventory';
import { getInventoryByCode } from '../fn/admin-controller/get-inventory-by-code';
import { GetInventoryByCode$Params } from '../fn/admin-controller/get-inventory-by-code';
import { getInventoryById } from '../fn/admin-controller/get-inventory-by-id';
import { GetInventoryById$Params } from '../fn/admin-controller/get-inventory-by-id';
import { InventoryWithRelations } from '../models/inventory-with-relations';

@Injectable({ providedIn: 'root' })
export class AdminControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminControllerGetInventoryByCode()` */
  static readonly AdminControllerGetInventoryByCodePath = '/admin/inventories/code/{code}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryByCode()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryByCode$Response(params: GetInventoryByCode$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryWithRelations>> {
    return getInventoryByCode(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryByCode$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryByCode(params: GetInventoryByCode$Params, context?: HttpContext): Observable<InventoryWithRelations> {
    return this.getInventoryByCode$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryWithRelations>): InventoryWithRelations => r.body)
    );
  }

  /** Path part for operation `adminControllerGetInventoryById()` */
  static readonly AdminControllerGetInventoryByIdPath = '/admin/inventories/id/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInventoryById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryById$Response(params: GetInventoryById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return getInventoryById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInventoryById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInventoryById(params: GetInventoryById$Params, context?: HttpContext): Observable<any> {
    return this.getInventoryById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `adminControllerCreateNewInventory()` */
  static readonly AdminControllerCreateNewInventoryPath = '/admin/inventories';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createNewInventory()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewInventory$Response(params?: CreateNewInventory$Params, context?: HttpContext): Observable<StrictHttpResponse<InventoryWithRelations>> {
    return createNewInventory(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createNewInventory$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createNewInventory(params?: CreateNewInventory$Params, context?: HttpContext): Observable<InventoryWithRelations> {
    return this.createNewInventory$Response(params, context).pipe(
      map((r: StrictHttpResponse<InventoryWithRelations>): InventoryWithRelations => r.body)
    );
  }

}
