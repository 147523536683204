/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface ImportFromCSV$Params {
  id: string;
  type?: string;
  
    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
}

export function importFromCSV(http: HttpClient, rootUrl: string, params: ImportFromCSV$Params, context?: HttpContext): Observable<StrictHttpResponse<{
}>> {
  const rb = new RequestBuilder(rootUrl, importFromCSV.PATH, 'post');
  if (params) {
    rb.path('id', params.id, {});
    rb.query('type', params.type, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'json', accept: 'application/json', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<{
      }>;
    })
  );
}

importFromCSV.PATH = '/import/inventory/{id}/from-csv';
