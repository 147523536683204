/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteById } from '../fn/admin-item-exhibitions-controller/admin-delete-by-id';
import { AdminDeleteById$Params } from '../fn/admin-item-exhibitions-controller/admin-delete-by-id';
import { adminFindById } from '../fn/admin-item-exhibitions-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-item-exhibitions-controller/admin-find-by-id';
import { create } from '../fn/admin-item-exhibitions-controller/create';
import { Create$Params } from '../fn/admin-item-exhibitions-controller/create';
import { ItemExhibitionWithRelations } from '../models/item-exhibition-with-relations';
import { updateById } from '../fn/admin-item-exhibitions-controller/update-by-id';
import { UpdateById$Params } from '../fn/admin-item-exhibitions-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class AdminItemExhibitionsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `itemExhibitionControllerAdminFindById()` */
  static readonly ItemExhibitionControllerAdminFindByIdPath = '/admin/item-exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ItemExhibitionWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<ItemExhibitionWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ItemExhibitionWithRelations>): ItemExhibitionWithRelations => r.body)
    );
  }

  /** Path part for operation `itemExhibitionControllerAdminDeleteById()` */
  static readonly ItemExhibitionControllerAdminDeleteByIdPath = '/admin/item-exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: AdminDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: AdminDeleteById$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemExhibitionControllerUpdateById()` */
  static readonly ItemExhibitionControllerUpdateByIdPath = '/admin/item-exhibitions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<any> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemExhibitionControllerCreate()` */
  static readonly ItemExhibitionControllerCreatePath = '/admin/item-exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<ItemExhibitionWithRelations>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<ItemExhibitionWithRelations> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<ItemExhibitionWithRelations>): ItemExhibitionWithRelations => r.body)
    );
  }

}
