/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminFind } from '../fn/admin-series-type-controller/admin-find';
import { AdminFind$Params } from '../fn/admin-series-type-controller/admin-find';
import { adminFindById } from '../fn/admin-series-type-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-series-type-controller/admin-find-by-id';
import { SeriesType } from '../models/series-type';

@Injectable({ providedIn: 'root' })
export class AdminSeriesTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `seriesTypeControllerAdminFindById()` */
  static readonly SeriesTypeControllerAdminFindByIdPath = '/admin/series-type/{id}';

  /**
   * Retrieve a specific series type by its ID.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<SeriesType>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve a specific series type by its ID.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<SeriesType> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<SeriesType>): SeriesType => r.body)
    );
  }

  /** Path part for operation `seriesTypeControllerAdminFind()` */
  static readonly SeriesTypeControllerAdminFindPath = '/admin/series-type';

  /**
   * Retrieve a list of all series types.
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: AdminFind$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<SeriesType>>> {
    return adminFind(this.http, this.rootUrl, params, context);
  }

  /**
   * Retrieve a list of all series types.
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: AdminFind$Params, context?: HttpContext): Observable<Array<SeriesType>> {
    return this.adminFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<SeriesType>>): Array<SeriesType> => r.body)
    );
  }

}
