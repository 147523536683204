/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { FileWithRelations } from '../models/file-with-relations';
import { findById } from '../fn/item-controller/find-by-id';
import { FindById$Params } from '../fn/item-controller/find-by-id';
import { getDocuments } from '../fn/item-controller/get-documents';
import { GetDocuments$Params } from '../fn/item-controller/get-documents';
import { getPictures } from '../fn/item-controller/get-pictures';
import { GetPictures$Params } from '../fn/item-controller/get-pictures';
import { ItemWithRelations } from '../models/item-with-relations';

@Injectable({ providedIn: 'root' })
export class ItemControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `itemControllerGetDocuments()` */
  static readonly ItemControllerGetDocumentsPath = '/items/{id}/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments$Response(params: GetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileWithRelations>>> {
    return getDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getDocuments(params: GetDocuments$Params, context?: HttpContext): Observable<Array<FileWithRelations>> {
    return this.getDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>): Array<FileWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemControllerGetPictures()` */
  static readonly ItemControllerGetPicturesPath = '/items/{id}/pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPictures()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPictures$Response(params: GetPictures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileWithRelations>>> {
    return getPictures(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPictures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPictures(params: GetPictures$Params, context?: HttpContext): Observable<Array<FileWithRelations>> {
    return this.getPictures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>): Array<FileWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemControllerFindById()` */
  static readonly ItemControllerFindByIdPath = '/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findById()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById$Response(params?: FindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ItemWithRelations>> {
    return findById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findById(params?: FindById$Params, context?: HttpContext): Observable<ItemWithRelations> {
    return this.findById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ItemWithRelations>): ItemWithRelations => r.body)
    );
  }

}
