/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getMarkingPlacesList } from '../fn/marking-place-controller/get-marking-places-list';
import { GetMarkingPlacesList$Params } from '../fn/marking-place-controller/get-marking-places-list';
import { MarkingPlace } from '../models/marking-place';

@Injectable({ providedIn: 'root' })
export class MarkingPlaceControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `markingPlaceControllerGetMarkingPlacesList()` */
  static readonly MarkingPlaceControllerGetMarkingPlacesListPath = '/marking-places';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getMarkingPlacesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarkingPlacesList$Response(params?: GetMarkingPlacesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<MarkingPlace>>> {
    return getMarkingPlacesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getMarkingPlacesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getMarkingPlacesList(params?: GetMarkingPlacesList$Params, context?: HttpContext): Observable<Array<MarkingPlace>> {
    return this.getMarkingPlacesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<MarkingPlace>>): Array<MarkingPlace> => r.body)
    );
  }

}
