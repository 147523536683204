/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface AdminUploadDocuments$Params {
  itemId?: string;
  inventoryId: string;
  isPublic?: boolean;
  authorId?: string;
  title?: string;
  titleEN?: string;
  description?: string;
  descriptionEN?: string;
  notesInternal?: string;
  documentTypeId?: string;
  
    /**
     * Request body for multipart/form-data based file upload
     */
    body: {
'file'?: Blob;
}
}

export function adminUploadDocuments(http: HttpClient, rootUrl: string, params: AdminUploadDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
  const rb = new RequestBuilder(rootUrl, adminUploadDocuments.PATH, 'post');
  if (params) {
    rb.query('itemId', params.itemId, {});
    rb.query('inventoryId', params.inventoryId, {});
    rb.query('isPublic', params.isPublic, {});
    rb.query('authorId', params.authorId, {});
    rb.query('title', params.title, {});
    rb.query('titleEN', params.titleEN, {});
    rb.query('description', params.description, {});
    rb.query('descriptionEN', params.descriptionEN, {});
    rb.query('notesInternal', params.notesInternal, {});
    rb.query('documentTypeId', params.documentTypeId, {});
    rb.body(params.body, 'multipart/form-data');
  }

  return http.request(
    rb.build({ responseType: 'text', accept: '*/*', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
    })
  );
}

adminUploadDocuments.PATH = '/admin/items/documents';
