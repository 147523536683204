/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { deleteUser } from '../fn/admin-user-controller/delete-user';
import { DeleteUser$Params } from '../fn/admin-user-controller/delete-user';
import { getAllUsers } from '../fn/admin-user-controller/get-all-users';
import { GetAllUsers$Params } from '../fn/admin-user-controller/get-all-users';
import { getAllUsersInfo } from '../fn/admin-user-controller/get-all-users-info';
import { GetAllUsersInfo$Params } from '../fn/admin-user-controller/get-all-users-info';
import { getUserById } from '../fn/admin-user-controller/get-user-by-id';
import { GetUserById$Params } from '../fn/admin-user-controller/get-user-by-id';
import { getUserInfoById } from '../fn/admin-user-controller/get-user-info-by-id';
import { GetUserInfoById$Params } from '../fn/admin-user-controller/get-user-info-by-id';
import { getUserInventoryRoles } from '../fn/admin-user-controller/get-user-inventory-roles';
import { GetUserInventoryRoles$Params } from '../fn/admin-user-controller/get-user-inventory-roles';
import { InventoryUser } from '../models/inventory-user';
import { patchUserById } from '../fn/admin-user-controller/patch-user-by-id';
import { PatchUserById$Params } from '../fn/admin-user-controller/patch-user-by-id';
import { resetUserPassword } from '../fn/admin-user-controller/reset-user-password';
import { ResetUserPassword$Params } from '../fn/admin-user-controller/reset-user-password';
import { setActiveStateToUser } from '../fn/admin-user-controller/set-active-state-to-user';
import { SetActiveStateToUser$Params } from '../fn/admin-user-controller/set-active-state-to-user';
import { User } from '../models/user';
import { UserInfo } from '../models/user-info';

@Injectable({ providedIn: 'root' })
export class AdminUserControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `adminUserControllerGetAllUsersInfo()` */
  static readonly AdminUserControllerGetAllUsersInfoPath = '/admin/users/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsersInfo()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersInfo$Response(params?: GetAllUsersInfo$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<UserInfo>>> {
    return getAllUsersInfo(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUsersInfo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsersInfo(params?: GetAllUsersInfo$Params, context?: HttpContext): Observable<Array<UserInfo>> {
    return this.getAllUsersInfo$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<UserInfo>>): Array<UserInfo> => r.body)
    );
  }

  /** Path part for operation `adminUserControllerResetUserPassword()` */
  static readonly AdminUserControllerResetUserPasswordPath = '/admin/users/{id}/change-password';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `resetUserPassword()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetUserPassword$Response(params: ResetUserPassword$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return resetUserPassword(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `resetUserPassword$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  resetUserPassword(params: ResetUserPassword$Params, context?: HttpContext): Observable<boolean> {
    return this.resetUserPassword$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `adminUserControllerGetUserInfoById()` */
  static readonly AdminUserControllerGetUserInfoByIdPath = '/admin/users/{id}/info';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInfoById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfoById$Response(params: GetUserInfoById$Params, context?: HttpContext): Observable<StrictHttpResponse<UserInfo>> {
    return getUserInfoById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserInfoById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInfoById(params: GetUserInfoById$Params, context?: HttpContext): Observable<UserInfo> {
    return this.getUserInfoById$Response(params, context).pipe(
      map((r: StrictHttpResponse<UserInfo>): UserInfo => r.body)
    );
  }

  /** Path part for operation `adminUserControllerGetUserInventoryRoles()` */
  static readonly AdminUserControllerGetUserInventoryRolesPath = '/admin/users/{id}/inventory-roles';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserInventoryRoles()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInventoryRoles$Response(params: GetUserInventoryRoles$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<InventoryUser>>> {
    return getUserInventoryRoles(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserInventoryRoles$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserInventoryRoles(params: GetUserInventoryRoles$Params, context?: HttpContext): Observable<Array<InventoryUser>> {
    return this.getUserInventoryRoles$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<InventoryUser>>): Array<InventoryUser> => r.body)
    );
  }

  /** Path part for operation `adminUserControllerSetActiveStateToUser()` */
  static readonly AdminUserControllerSetActiveStateToUserPath = '/admin/users/{id}/state';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `setActiveStateToUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveStateToUser$Response(params: SetActiveStateToUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return setActiveStateToUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `setActiveStateToUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  setActiveStateToUser(params: SetActiveStateToUser$Params, context?: HttpContext): Observable<void> {
    return this.setActiveStateToUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminUserControllerGetUserById()` */
  static readonly AdminUserControllerGetUserByIdPath = '/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getUserById()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById$Response(params: GetUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<User>> {
    return getUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getUserById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getUserById(params: GetUserById$Params, context?: HttpContext): Observable<User> {
    return this.getUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<User>): User => r.body)
    );
  }

  /** Path part for operation `adminUserControllerDeleteUser()` */
  static readonly AdminUserControllerDeleteUserPath = '/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteUser()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser$Response(params: DeleteUser$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return deleteUser(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteUser$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteUser(params: DeleteUser$Params, context?: HttpContext): Observable<void> {
    return this.deleteUser$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `adminUserControllerPatchUserById()` */
  static readonly AdminUserControllerPatchUserByIdPath = '/admin/users/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `patchUserById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserById$Response(params: PatchUserById$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return patchUserById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `patchUserById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  patchUserById(params: PatchUserById$Params, context?: HttpContext): Observable<boolean> {
    return this.patchUserById$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `adminUserControllerGetAllUsers()` */
  static readonly AdminUserControllerGetAllUsersPath = '/admin/users';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getAllUsers()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers$Response(params?: GetAllUsers$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<User>>> {
    return getAllUsers(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getAllUsers$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getAllUsers(params?: GetAllUsers$Params, context?: HttpContext): Observable<Array<User>> {
    return this.getAllUsers$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<User>>): Array<User> => r.body)
    );
  }

}
