/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { addParent } from '../fn/admin-items-controller/add-parent';
import { AddParent$Params } from '../fn/admin-items-controller/add-parent';
import { adminDeleteById } from '../fn/admin-items-controller/admin-delete-by-id';
import { AdminDeleteById$Params } from '../fn/admin-items-controller/admin-delete-by-id';
import { adminDeleteItemDocument } from '../fn/admin-items-controller/admin-delete-item-document';
import { AdminDeleteItemDocument$Params } from '../fn/admin-items-controller/admin-delete-item-document';
import { adminDeleteItemPicture } from '../fn/admin-items-controller/admin-delete-item-picture';
import { AdminDeleteItemPicture$Params } from '../fn/admin-items-controller/admin-delete-item-picture';
import { adminFindById } from '../fn/admin-items-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-items-controller/admin-find-by-id';
import { adminGetAuctionsListForItem } from '../fn/admin-items-controller/admin-get-auctions-list-for-item';
import { AdminGetAuctionsListForItem$Params } from '../fn/admin-items-controller/admin-get-auctions-list-for-item';
import { adminGetDocuments } from '../fn/admin-items-controller/admin-get-documents';
import { AdminGetDocuments$Params } from '../fn/admin-items-controller/admin-get-documents';
import { adminGetExhibitionsListForItem } from '../fn/admin-items-controller/admin-get-exhibitions-list-for-item';
import { AdminGetExhibitionsListForItem$Params } from '../fn/admin-items-controller/admin-get-exhibitions-list-for-item';
import { adminGetInventoryItems } from '../fn/admin-items-controller/admin-get-inventory-items';
import { AdminGetInventoryItems$Params } from '../fn/admin-items-controller/admin-get-inventory-items';
import { adminGetOwnersListForItem } from '../fn/admin-items-controller/admin-get-owners-list-for-item';
import { AdminGetOwnersListForItem$Params } from '../fn/admin-items-controller/admin-get-owners-list-for-item';
import { adminGetPictures } from '../fn/admin-items-controller/admin-get-pictures';
import { AdminGetPictures$Params } from '../fn/admin-items-controller/admin-get-pictures';
import { adminGetPublicationsListForItem } from '../fn/admin-items-controller/admin-get-publications-list-for-item';
import { AdminGetPublicationsListForItem$Params } from '../fn/admin-items-controller/admin-get-publications-list-for-item';
import { adminUpdateById } from '../fn/admin-items-controller/admin-update-by-id';
import { AdminUpdateById$Params } from '../fn/admin-items-controller/admin-update-by-id';
import { adminUpdateOtherSide } from '../fn/admin-items-controller/admin-update-other-side';
import { AdminUpdateOtherSide$Params } from '../fn/admin-items-controller/admin-update-other-side';
import { adminUploadDocuments } from '../fn/admin-items-controller/admin-upload-documents';
import { AdminUploadDocuments$Params } from '../fn/admin-items-controller/admin-upload-documents';
import { adminUploadPictures } from '../fn/admin-items-controller/admin-upload-pictures';
import { AdminUploadPictures$Params } from '../fn/admin-items-controller/admin-upload-pictures';
import { create } from '../fn/admin-items-controller/create';
import { Create$Params } from '../fn/admin-items-controller/create';
import { createRelation } from '../fn/admin-items-controller/create-relation';
import { CreateRelation$Params } from '../fn/admin-items-controller/create-relation';
import { deleteRelation } from '../fn/admin-items-controller/delete-relation';
import { DeleteRelation$Params } from '../fn/admin-items-controller/delete-relation';
import { FileWithRelations } from '../models/file-with-relations';
import { Item } from '../models/item';
import { ItemAuctionWithRelations } from '../models/item-auction-with-relations';
import { ItemExhibitionWithRelations } from '../models/item-exhibition-with-relations';
import { ItemOwnerWithRelations } from '../models/item-owner-with-relations';
import { ItemPublicationWithRelations } from '../models/item-publication-with-relations';
import { ItemWithRelations } from '../models/item-with-relations';
import { RelationPair } from '../models/relation-pair';
import { removeParent } from '../fn/admin-items-controller/remove-parent';
import { RemoveParent$Params } from '../fn/admin-items-controller/remove-parent';

@Injectable({ providedIn: 'root' })
export class AdminItemsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `itemControllerAdminGetInventoryItems()` */
  static readonly ItemControllerAdminGetInventoryItemsPath = '/admin/inventory/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetInventoryItems()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetInventoryItems$Response(params: AdminGetInventoryItems$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemWithRelations>>> {
    return adminGetInventoryItems(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetInventoryItems$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetInventoryItems(params: AdminGetInventoryItems$Params, context?: HttpContext): Observable<Array<ItemWithRelations>> {
    return this.adminGetInventoryItems$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemWithRelations>>): Array<ItemWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminDeleteItemDocument()` */
  static readonly ItemControllerAdminDeleteItemDocumentPath = '/admin/items/documents/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteItemDocument()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemDocument$Response(params: AdminDeleteItemDocument$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteItemDocument(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteItemDocument$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemDocument(params: AdminDeleteItemDocument$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteItemDocument$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminGetDocuments()` */
  static readonly ItemControllerAdminGetDocumentsPath = '/admin/items/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetDocuments()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetDocuments$Response(params: AdminGetDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileWithRelations>>> {
    return adminGetDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetDocuments$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetDocuments(params: AdminGetDocuments$Params, context?: HttpContext): Observable<Array<FileWithRelations>> {
    return this.adminGetDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>): Array<FileWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminUploadDocuments()` */
  static readonly ItemControllerAdminUploadDocumentsPath = '/admin/items/documents';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUploadDocuments()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadDocuments$Response(params: AdminUploadDocuments$Params, context?: HttpContext): Observable<StrictHttpResponse<void>> {
    return adminUploadDocuments(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUploadDocuments$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadDocuments(params: AdminUploadDocuments$Params, context?: HttpContext): Observable<void> {
    return this.adminUploadDocuments$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminUpdateOtherSide()` */
  static readonly ItemControllerAdminUpdateOtherSidePath = '/admin/items/other-side';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateOtherSide()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateOtherSide$Response(params: AdminUpdateOtherSide$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminUpdateOtherSide(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateOtherSide$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateOtherSide(params: AdminUpdateOtherSide$Params, context?: HttpContext): Observable<any> {
    return this.adminUpdateOtherSide$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemControllerAddParent()` */
  static readonly ItemControllerAddParentPath = '/admin/items/parent/add';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  addParent$Response(params: AddParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Item>> {
    return addParent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  addParent(params: AddParent$Params, context?: HttpContext): Observable<Item> {
    return this.addParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Item>): Item => r.body)
    );
  }

  /** Path part for operation `itemControllerRemoveParent()` */
  static readonly ItemControllerRemoveParentPath = '/admin/items/parent/remove';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeParent()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeParent$Response(params: RemoveParent$Params, context?: HttpContext): Observable<StrictHttpResponse<Item>> {
    return removeParent(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeParent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeParent(params: RemoveParent$Params, context?: HttpContext): Observable<Item> {
    return this.removeParent$Response(params, context).pipe(
      map((r: StrictHttpResponse<Item>): Item => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminDeleteItemPicture()` */
  static readonly ItemControllerAdminDeleteItemPicturePath = '/admin/items/pictures/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteItemPicture()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemPicture$Response(params: AdminDeleteItemPicture$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteItemPicture(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteItemPicture$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteItemPicture(params: AdminDeleteItemPicture$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteItemPicture$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminGetPictures()` */
  static readonly ItemControllerAdminGetPicturesPath = '/admin/items/pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPictures()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPictures$Response(params: AdminGetPictures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileWithRelations>>> {
    return adminGetPictures(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPictures$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPictures(params: AdminGetPictures$Params, context?: HttpContext): Observable<Array<FileWithRelations>> {
    return this.adminGetPictures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>): Array<FileWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminUploadPictures()` */
  static readonly ItemControllerAdminUploadPicturesPath = '/admin/items/pictures';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUploadPictures()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadPictures$Response(params: AdminUploadPictures$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<FileWithRelations>>> {
    return adminUploadPictures(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUploadPictures$Response()` instead.
   *
   * This method sends `multipart/form-data` and handles request body of type `multipart/form-data`.
   */
  adminUploadPictures(params: AdminUploadPictures$Params, context?: HttpContext): Observable<Array<FileWithRelations>> {
    return this.adminUploadPictures$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<FileWithRelations>>): Array<FileWithRelations> => r.body)
    );
  }

  /** Path part for operation `i2RelationControllerCreateRelation()` */
  static readonly I2RelationControllerCreateRelationPath = '/admin/items/relation/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  createRelation$Response(params?: CreateRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<RelationPair>> {
    return createRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  createRelation(params?: CreateRelation$Params, context?: HttpContext): Observable<RelationPair> {
    return this.createRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<RelationPair>): RelationPair => r.body)
    );
  }

  /** Path part for operation `i2RelationControllerDeleteRelation()` */
  static readonly I2RelationControllerDeleteRelationPath = '/admin/items/relation/delete';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `deleteRelation()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelation$Response(params?: DeleteRelation$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return deleteRelation(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `deleteRelation$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  deleteRelation(params?: DeleteRelation$Params, context?: HttpContext): Observable<any> {
    return this.deleteRelation$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemAuctionControllerAdminGetAuctionsListForItem()` */
  static readonly ItemAuctionControllerAdminGetAuctionsListForItemPath = '/admin/items/{id}/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetAuctionsListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAuctionsListForItem$Response(params: AdminGetAuctionsListForItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemAuctionWithRelations>>> {
    return adminGetAuctionsListForItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetAuctionsListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetAuctionsListForItem(params: AdminGetAuctionsListForItem$Params, context?: HttpContext): Observable<Array<ItemAuctionWithRelations>> {
    return this.adminGetAuctionsListForItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemAuctionWithRelations>>): Array<ItemAuctionWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemExhibitionControllerAdminGetExhibitionsListForItem()` */
  static readonly ItemExhibitionControllerAdminGetExhibitionsListForItemPath = '/admin/items/{id}/exhibitions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetExhibitionsListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExhibitionsListForItem$Response(params: AdminGetExhibitionsListForItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemExhibitionWithRelations>>> {
    return adminGetExhibitionsListForItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetExhibitionsListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetExhibitionsListForItem(params: AdminGetExhibitionsListForItem$Params, context?: HttpContext): Observable<Array<ItemExhibitionWithRelations>> {
    return this.adminGetExhibitionsListForItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemExhibitionWithRelations>>): Array<ItemExhibitionWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemOwnerControllerAdminGetOwnersListForItem()` */
  static readonly ItemOwnerControllerAdminGetOwnersListForItemPath = '/admin/items/{id}/owners';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetOwnersListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOwnersListForItem$Response(params: AdminGetOwnersListForItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemOwnerWithRelations>>> {
    return adminGetOwnersListForItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetOwnersListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetOwnersListForItem(params: AdminGetOwnersListForItem$Params, context?: HttpContext): Observable<Array<ItemOwnerWithRelations>> {
    return this.adminGetOwnersListForItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemOwnerWithRelations>>): Array<ItemOwnerWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemPublicationControllerAdminGetPublicationsListForItem()` */
  static readonly ItemPublicationControllerAdminGetPublicationsListForItemPath = '/admin/items/{id}/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetPublicationsListForItem()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPublicationsListForItem$Response(params: AdminGetPublicationsListForItem$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemPublicationWithRelations>>> {
    return adminGetPublicationsListForItem(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetPublicationsListForItem$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetPublicationsListForItem(params: AdminGetPublicationsListForItem$Params, context?: HttpContext): Observable<Array<ItemPublicationWithRelations>> {
    return this.adminGetPublicationsListForItem$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemPublicationWithRelations>>): Array<ItemPublicationWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminFindById()` */
  static readonly ItemControllerAdminFindByIdPath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params?: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<ItemWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params?: AdminFindById$Params, context?: HttpContext): Observable<ItemWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<ItemWithRelations>): ItemWithRelations => r.body)
    );
  }

  /** Path part for operation `itemControllerCreate()` */
  static readonly ItemControllerCreatePath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Item>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params: Create$Params, context?: HttpContext): Observable<Item> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Item>): Item => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminDeleteById()` */
  static readonly ItemControllerAdminDeleteByIdPath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: AdminDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: AdminDeleteById$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemControllerAdminUpdateById()` */
  static readonly ItemControllerAdminUpdateByIdPath = '/admin/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateById$Response(params: AdminUpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminUpdateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateById(params: AdminUpdateById$Params, context?: HttpContext): Observable<any> {
    return this.adminUpdateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
