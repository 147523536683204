/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Author } from '../models/author';
import { AuthorWithRelations } from '../models/author-with-relations';
import { editorCreateAuthor } from '../fn/editor-author-controller/editor-create-author';
import { EditorCreateAuthor$Params } from '../fn/editor-author-controller/editor-create-author';
import { editorDeleteAuthor } from '../fn/editor-author-controller/editor-delete-author';
import { EditorDeleteAuthor$Params } from '../fn/editor-author-controller/editor-delete-author';
import { editorGetAuthor } from '../fn/editor-author-controller/editor-get-author';
import { EditorGetAuthor$Params } from '../fn/editor-author-controller/editor-get-author';
import { editorGetAuthors } from '../fn/editor-author-controller/editor-get-authors';
import { EditorGetAuthors$Params } from '../fn/editor-author-controller/editor-get-authors';
import { editorUpdateAuthor } from '../fn/editor-author-controller/editor-update-author';
import { EditorUpdateAuthor$Params } from '../fn/editor-author-controller/editor-update-author';

@Injectable({ providedIn: 'root' })
export class EditorAuthorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authorControllerEditorGetAuthor()` */
  static readonly AuthorControllerEditorGetAuthorPath = '/editor/author';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorGetAuthor()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthor$Response(params: EditorGetAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<AuthorWithRelations>> {
    return editorGetAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorGetAuthor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthor(params: EditorGetAuthor$Params, context?: HttpContext): Observable<AuthorWithRelations> {
    return this.editorGetAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuthorWithRelations>): AuthorWithRelations => r.body)
    );
  }

  /** Path part for operation `authorControllerEditorGetAuthors()` */
  static readonly AuthorControllerEditorGetAuthorsPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorGetAuthors()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthors$Response(params?: EditorGetAuthors$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AuthorWithRelations>>> {
    return editorGetAuthors(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorGetAuthors$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetAuthors(params?: EditorGetAuthors$Params, context?: HttpContext): Observable<Array<AuthorWithRelations>> {
    return this.editorGetAuthors$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuthorWithRelations>>): Array<AuthorWithRelations> => r.body)
    );
  }

  /** Path part for operation `authorControllerEditorCreateAuthor()` */
  static readonly AuthorControllerEditorCreateAuthorPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorCreateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorCreateAuthor$Response(params?: EditorCreateAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<Author>> {
    return editorCreateAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorCreateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorCreateAuthor(params?: EditorCreateAuthor$Params, context?: HttpContext): Observable<Author> {
    return this.editorCreateAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<Author>): Author => r.body)
    );
  }

  /** Path part for operation `authorControllerEditorDeleteAuthor()` */
  static readonly AuthorControllerEditorDeleteAuthorPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorDeleteAuthor()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorDeleteAuthor$Response(params: EditorDeleteAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return editorDeleteAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorDeleteAuthor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorDeleteAuthor(params: EditorDeleteAuthor$Params, context?: HttpContext): Observable<any> {
    return this.editorDeleteAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `authorControllerEditorUpdateAuthor()` */
  static readonly AuthorControllerEditorUpdateAuthorPath = '/editor/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorUpdateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorUpdateAuthor$Response(params: EditorUpdateAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return editorUpdateAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorUpdateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorUpdateAuthor(params: EditorUpdateAuthor$Params, context?: HttpContext): Observable<any> {
    return this.editorUpdateAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
