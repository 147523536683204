/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getItemTypesList } from '../fn/item-type-controller/get-item-types-list';
import { GetItemTypesList$Params } from '../fn/item-type-controller/get-item-types-list';
import { ItemTypeWithRelations } from '../models/item-type-with-relations';

@Injectable({ providedIn: 'root' })
export class ItemTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `itemTypeControllerGetItemTypesList()` */
  static readonly ItemTypeControllerGetItemTypesListPath = '/item-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getItemTypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemTypesList$Response(params?: GetItemTypesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemTypeWithRelations>>> {
    return getItemTypesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getItemTypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getItemTypesList(params?: GetItemTypesList$Params, context?: HttpContext): Observable<Array<ItemTypeWithRelations>> {
    return this.getItemTypesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemTypeWithRelations>>): Array<ItemTypeWithRelations> => r.body)
    );
  }

}
