/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContactWithRelations } from '../models/contact-with-relations';
import { inventoryCreateAuthor } from '../fn/inventory-contact-controller/inventory-create-author';
import { InventoryCreateAuthor$Params } from '../fn/inventory-contact-controller/inventory-create-author';
import { inventoryDeleteContact } from '../fn/inventory-contact-controller/inventory-delete-contact';
import { InventoryDeleteContact$Params } from '../fn/inventory-contact-controller/inventory-delete-contact';
import { inventoryUpdateContact } from '../fn/inventory-contact-controller/inventory-update-contact';
import { InventoryUpdateContact$Params } from '../fn/inventory-contact-controller/inventory-update-contact';
import { moveContactToGlobal } from '../fn/inventory-contact-controller/move-contact-to-global';
import { MoveContactToGlobal$Params } from '../fn/inventory-contact-controller/move-contact-to-global';

@Injectable({ providedIn: 'root' })
export class InventoryContactControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactControllerMoveContactToGlobal()` */
  static readonly ContactControllerMoveContactToGlobalPath = '/inventory/contacts/move-to-global';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveContactToGlobal()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveContactToGlobal$Response(params: MoveContactToGlobal$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return moveContactToGlobal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveContactToGlobal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveContactToGlobal(params: MoveContactToGlobal$Params, context?: HttpContext): Observable<any> {
    return this.moveContactToGlobal$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `contactControllerInventoryCreateAuthor()` */
  static readonly ContactControllerInventoryCreateAuthorPath = '/inventory/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryCreateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryCreateAuthor$Response(params: InventoryCreateAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactWithRelations>> {
    return inventoryCreateAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryCreateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryCreateAuthor(params: InventoryCreateAuthor$Params, context?: HttpContext): Observable<ContactWithRelations> {
    return this.inventoryCreateAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactWithRelations>): ContactWithRelations => r.body)
    );
  }

  /** Path part for operation `contactControllerInventoryDeleteContact()` */
  static readonly ContactControllerInventoryDeleteContactPath = '/inventory/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryDeleteContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryDeleteContact$Response(params: InventoryDeleteContact$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return inventoryDeleteContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryDeleteContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryDeleteContact(params: InventoryDeleteContact$Params, context?: HttpContext): Observable<any> {
    return this.inventoryDeleteContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `contactControllerInventoryUpdateContact()` */
  static readonly ContactControllerInventoryUpdateContactPath = '/inventory/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryUpdateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryUpdateContact$Response(params: InventoryUpdateContact$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return inventoryUpdateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryUpdateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryUpdateContact(params: InventoryUpdateContact$Params, context?: HttpContext): Observable<any> {
    return this.inventoryUpdateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
