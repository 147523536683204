/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteById } from '../fn/admin-files-controller/admin-delete-by-id';
import { AdminDeleteById$Params } from '../fn/admin-files-controller/admin-delete-by-id';
import { adminTogglePublicById } from '../fn/admin-files-controller/admin-toggle-public-by-id';
import { AdminTogglePublicById$Params } from '../fn/admin-files-controller/admin-toggle-public-by-id';
import { adminUpdateById } from '../fn/admin-files-controller/admin-update-by-id';
import { AdminUpdateById$Params } from '../fn/admin-files-controller/admin-update-by-id';

@Injectable({ providedIn: 'root' })
export class AdminFilesControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `fileControllerAdminTogglePublicById()` */
  static readonly FileControllerAdminTogglePublicByIdPath = '/admin/files/{id}/toggle-public';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminTogglePublicById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTogglePublicById$Response(params: AdminTogglePublicById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminTogglePublicById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminTogglePublicById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminTogglePublicById(params: AdminTogglePublicById$Params, context?: HttpContext): Observable<any> {
    return this.adminTogglePublicById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `fileControllerAdminDeleteById()` */
  static readonly FileControllerAdminDeleteByIdPath = '/admin/files/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: AdminDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: AdminDeleteById$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `fileControllerAdminUpdateById()` */
  static readonly FileControllerAdminUpdateByIdPath = '/admin/files/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminUpdateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateById$Response(params: AdminUpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminUpdateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminUpdateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  adminUpdateById(params: AdminUpdateById$Params, context?: HttpContext): Observable<any> {
    return this.adminUpdateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
