/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { ContactWithRelations } from '../models/contact-with-relations';
import { editorCreateContact } from '../fn/editor-contact-controller/editor-create-contact';
import { EditorCreateContact$Params } from '../fn/editor-contact-controller/editor-create-contact';
import { editorDeleteContact } from '../fn/editor-contact-controller/editor-delete-contact';
import { EditorDeleteContact$Params } from '../fn/editor-contact-controller/editor-delete-contact';
import { editorGetContact } from '../fn/editor-contact-controller/editor-get-contact';
import { EditorGetContact$Params } from '../fn/editor-contact-controller/editor-get-contact';
import { editorGetContacts } from '../fn/editor-contact-controller/editor-get-contacts';
import { EditorGetContacts$Params } from '../fn/editor-contact-controller/editor-get-contacts';
import { editorUpdateContact } from '../fn/editor-contact-controller/editor-update-contact';
import { EditorUpdateContact$Params } from '../fn/editor-contact-controller/editor-update-contact';

@Injectable({ providedIn: 'root' })
export class EditorContactControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `contactControllerEditorGetContact()` */
  static readonly ContactControllerEditorGetContactPath = '/editor/contact';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorGetContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetContact$Response(params: EditorGetContact$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactWithRelations>> {
    return editorGetContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorGetContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetContact(params: EditorGetContact$Params, context?: HttpContext): Observable<ContactWithRelations> {
    return this.editorGetContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactWithRelations>): ContactWithRelations => r.body)
    );
  }

  /** Path part for operation `contactControllerEditorGetContacts()` */
  static readonly ContactControllerEditorGetContactsPath = '/editor/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorGetContacts()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetContacts$Response(params?: EditorGetContacts$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ContactWithRelations>>> {
    return editorGetContacts(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorGetContacts$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorGetContacts(params?: EditorGetContacts$Params, context?: HttpContext): Observable<Array<ContactWithRelations>> {
    return this.editorGetContacts$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ContactWithRelations>>): Array<ContactWithRelations> => r.body)
    );
  }

  /** Path part for operation `contactControllerEditorCreateContact()` */
  static readonly ContactControllerEditorCreateContactPath = '/editor/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorCreateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorCreateContact$Response(params?: EditorCreateContact$Params, context?: HttpContext): Observable<StrictHttpResponse<ContactWithRelations>> {
    return editorCreateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorCreateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorCreateContact(params?: EditorCreateContact$Params, context?: HttpContext): Observable<ContactWithRelations> {
    return this.editorCreateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContactWithRelations>): ContactWithRelations => r.body)
    );
  }

  /** Path part for operation `contactControllerEditorDeleteContact()` */
  static readonly ContactControllerEditorDeleteContactPath = '/editor/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorDeleteContact()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorDeleteContact$Response(params: EditorDeleteContact$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return editorDeleteContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorDeleteContact$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  editorDeleteContact(params: EditorDeleteContact$Params, context?: HttpContext): Observable<any> {
    return this.editorDeleteContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `contactControllerEditorUpdateContact()` */
  static readonly ContactControllerEditorUpdateContactPath = '/editor/contacts';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `editorUpdateContact()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorUpdateContact$Response(params: EditorUpdateContact$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return editorUpdateContact(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `editorUpdateContact$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  editorUpdateContact(params: EditorUpdateContact$Params, context?: HttpContext): Observable<any> {
    return this.editorUpdateContact$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
