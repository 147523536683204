/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { Author } from '../models/author';
import { inventoryCreateAuthor } from '../fn/inventory-author-controller/inventory-create-author';
import { InventoryCreateAuthor$Params } from '../fn/inventory-author-controller/inventory-create-author';
import { inventoryDeleteAuthor } from '../fn/inventory-author-controller/inventory-delete-author';
import { InventoryDeleteAuthor$Params } from '../fn/inventory-author-controller/inventory-delete-author';
import { inventoryUpdateAuthor } from '../fn/inventory-author-controller/inventory-update-author';
import { InventoryUpdateAuthor$Params } from '../fn/inventory-author-controller/inventory-update-author';
import { moveAuthorToGlobal } from '../fn/inventory-author-controller/move-author-to-global';
import { MoveAuthorToGlobal$Params } from '../fn/inventory-author-controller/move-author-to-global';

@Injectable({ providedIn: 'root' })
export class InventoryAuthorControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `authorControllerMoveAuthorToGlobal()` */
  static readonly AuthorControllerMoveAuthorToGlobalPath = '/inventory/authors/move-to-global';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `moveAuthorToGlobal()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveAuthorToGlobal$Response(params: MoveAuthorToGlobal$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return moveAuthorToGlobal(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `moveAuthorToGlobal$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  moveAuthorToGlobal(params: MoveAuthorToGlobal$Params, context?: HttpContext): Observable<any> {
    return this.moveAuthorToGlobal$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `authorControllerInventoryCreateAuthor()` */
  static readonly AuthorControllerInventoryCreateAuthorPath = '/inventory/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryCreateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryCreateAuthor$Response(params: InventoryCreateAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<Author>> {
    return inventoryCreateAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryCreateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryCreateAuthor(params: InventoryCreateAuthor$Params, context?: HttpContext): Observable<Author> {
    return this.inventoryCreateAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<Author>): Author => r.body)
    );
  }

  /** Path part for operation `authorControllerInventoryDeleteAuthor()` */
  static readonly AuthorControllerInventoryDeleteAuthorPath = '/inventory/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryDeleteAuthor()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryDeleteAuthor$Response(params: InventoryDeleteAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return inventoryDeleteAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryDeleteAuthor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  inventoryDeleteAuthor(params: InventoryDeleteAuthor$Params, context?: HttpContext): Observable<any> {
    return this.inventoryDeleteAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `authorControllerInventoryUpdateAuthor()` */
  static readonly AuthorControllerInventoryUpdateAuthorPath = '/inventory/authors';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `inventoryUpdateAuthor()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryUpdateAuthor$Response(params: InventoryUpdateAuthor$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return inventoryUpdateAuthor(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `inventoryUpdateAuthor$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  inventoryUpdateAuthor(params: InventoryUpdateAuthor$Params, context?: HttpContext): Observable<any> {
    return this.inventoryUpdateAuthor$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
