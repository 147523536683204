/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { getPublicationTypesList } from '../fn/publication-type-controller/get-publication-types-list';
import { GetPublicationTypesList$Params } from '../fn/publication-type-controller/get-publication-types-list';
import { PublicationTypeWithRelations } from '../models/publication-type-with-relations';

@Injectable({ providedIn: 'root' })
export class PublicationTypeControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `publicationTypeControllerGetPublicationTypesList()` */
  static readonly PublicationTypeControllerGetPublicationTypesListPath = '/publication-types';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPublicationTypesList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicationTypesList$Response(params?: GetPublicationTypesList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PublicationTypeWithRelations>>> {
    return getPublicationTypesList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPublicationTypesList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPublicationTypesList(params?: GetPublicationTypesList$Params, context?: HttpContext): Observable<Array<PublicationTypeWithRelations>> {
    return this.getPublicationTypesList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PublicationTypeWithRelations>>): Array<PublicationTypeWithRelations> => r.body)
    );
  }

}
