/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteById } from '../fn/admin-publications-controller/admin-delete-by-id';
import { AdminDeleteById$Params } from '../fn/admin-publications-controller/admin-delete-by-id';
import { adminFind } from '../fn/admin-publications-controller/admin-find';
import { AdminFind$Params } from '../fn/admin-publications-controller/admin-find';
import { adminFindById } from '../fn/admin-publications-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-publications-controller/admin-find-by-id';
import { adminGetList } from '../fn/admin-publications-controller/admin-get-list';
import { AdminGetList$Params } from '../fn/admin-publications-controller/admin-get-list';
import { adminGetListForPublication } from '../fn/admin-publications-controller/admin-get-list-for-publication';
import { AdminGetListForPublication$Params } from '../fn/admin-publications-controller/admin-get-list-for-publication';
import { approve } from '../fn/admin-publications-controller/approve';
import { Approve$Params } from '../fn/admin-publications-controller/approve';
import { create } from '../fn/admin-publications-controller/create';
import { Create$Params } from '../fn/admin-publications-controller/create';
import { ItemPublicationWithRelations } from '../models/item-publication-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';
import { Publication } from '../models/publication';
import { PublicationWithRelations } from '../models/publication-with-relations';
import { replaceById } from '../fn/admin-publications-controller/replace-by-id';
import { ReplaceById$Params } from '../fn/admin-publications-controller/replace-by-id';
import { updateAll } from '../fn/admin-publications-controller/update-all';
import { UpdateAll$Params } from '../fn/admin-publications-controller/update-all';
import { updateById } from '../fn/admin-publications-controller/update-by-id';
import { UpdateById$Params } from '../fn/admin-publications-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class AdminPublicationsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `publicationControllerAdminGetList()` */
  static readonly PublicationControllerAdminGetListPath = '/admin/publications/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetList()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetList$Response(params?: AdminGetList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PublicationWithRelations>>> {
    return adminGetList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetList(params?: AdminGetList$Params, context?: HttpContext): Observable<Array<PublicationWithRelations>> {
    return this.adminGetList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PublicationWithRelations>>): Array<PublicationWithRelations> => r.body)
    );
  }

  /** Path part for operation `publicationControllerApprove()` */
  static readonly PublicationControllerApprovePath = '/admin/publications/{id}/approve';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approve()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve$Response(params: Approve$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return approve(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approve$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approve(params: Approve$Params, context?: HttpContext): Observable<any> {
    return this.approve$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `itemPublicationControllerAdminGetListForPublication()` */
  static readonly ItemPublicationControllerAdminGetListForPublicationPath = '/admin/publications/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetListForPublication()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForPublication$Response(params: AdminGetListForPublication$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemPublicationWithRelations>>> {
    return adminGetListForPublication(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetListForPublication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForPublication(params: AdminGetListForPublication$Params, context?: HttpContext): Observable<Array<ItemPublicationWithRelations>> {
    return this.adminGetListForPublication$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemPublicationWithRelations>>): Array<ItemPublicationWithRelations> => r.body)
    );
  }

  /** Path part for operation `publicationControllerAdminFindById()` */
  static readonly PublicationControllerAdminFindByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<PublicationWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<PublicationWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<PublicationWithRelations>): PublicationWithRelations => r.body)
    );
  }

  /** Path part for operation `publicationControllerReplaceById()` */
  static readonly PublicationControllerReplaceByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: ReplaceById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return replaceById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: ReplaceById$Params, context?: HttpContext): Observable<any> {
    return this.replaceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `publicationControllerAdminDeleteById()` */
  static readonly PublicationControllerAdminDeleteByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: AdminDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: AdminDeleteById$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `publicationControllerUpdateById()` */
  static readonly PublicationControllerUpdateByIdPath = '/admin/publications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<any> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `publicationControllerAdminFind()` */
  static readonly PublicationControllerAdminFindPath = '/admin/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: AdminFind$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<PublicationWithRelations>>> {
    return adminFind(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: AdminFind$Params, context?: HttpContext): Observable<Array<PublicationWithRelations>> {
    return this.adminFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<PublicationWithRelations>>): Array<PublicationWithRelations> => r.body)
    );
  }

  /** Path part for operation `publicationControllerCreate()` */
  static readonly PublicationControllerCreatePath = '/admin/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Publication>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<Publication> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Publication>): Publication => r.body)
    );
  }

  /** Path part for operation `publicationControllerUpdateAll()` */
  static readonly PublicationControllerUpdateAllPath = '/admin/publications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: UpdateAll$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return updateAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: UpdateAll$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.updateAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

}
