/* tslint:disable */
/* eslint-disable */
/* Code generated by ng-openapi-gen DO NOT EDIT. */

import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { adminDeleteById } from '../fn/admin-auctions-controller/admin-delete-by-id';
import { AdminDeleteById$Params } from '../fn/admin-auctions-controller/admin-delete-by-id';
import { adminFind } from '../fn/admin-auctions-controller/admin-find';
import { AdminFind$Params } from '../fn/admin-auctions-controller/admin-find';
import { adminFindById } from '../fn/admin-auctions-controller/admin-find-by-id';
import { AdminFindById$Params } from '../fn/admin-auctions-controller/admin-find-by-id';
import { adminGetListForAuction } from '../fn/admin-auctions-controller/admin-get-list-for-auction';
import { AdminGetListForAuction$Params } from '../fn/admin-auctions-controller/admin-get-list-for-auction';
import { Auction } from '../models/auction';
import { AuctionWithRelations } from '../models/auction-with-relations';
import { create } from '../fn/admin-auctions-controller/create';
import { Create$Params } from '../fn/admin-auctions-controller/create';
import { getList } from '../fn/admin-auctions-controller/get-list';
import { GetList$Params } from '../fn/admin-auctions-controller/get-list';
import { ItemAuctionWithRelations } from '../models/item-auction-with-relations';
import { Count as LoopbackCount } from '../models/loopback/count';
import { replaceById } from '../fn/admin-auctions-controller/replace-by-id';
import { ReplaceById$Params } from '../fn/admin-auctions-controller/replace-by-id';
import { updateAll } from '../fn/admin-auctions-controller/update-all';
import { UpdateAll$Params } from '../fn/admin-auctions-controller/update-all';
import { updateById } from '../fn/admin-auctions-controller/update-by-id';
import { UpdateById$Params } from '../fn/admin-auctions-controller/update-by-id';

@Injectable({ providedIn: 'root' })
export class AdminAuctionsControllerService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `auctionControllerGetList()` */
  static readonly AuctionControllerGetListPath = '/admin/auctions/list';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList$Response(params?: GetList$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AuctionWithRelations>>> {
    return getList(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getList(params?: GetList$Params, context?: HttpContext): Observable<Array<AuctionWithRelations>> {
    return this.getList$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuctionWithRelations>>): Array<AuctionWithRelations> => r.body)
    );
  }

  /** Path part for operation `itemAuctionControllerAdminGetListForAuction()` */
  static readonly ItemAuctionControllerAdminGetListForAuctionPath = '/admin/auctions/{id}/items';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminGetListForAuction()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForAuction$Response(params: AdminGetListForAuction$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<ItemAuctionWithRelations>>> {
    return adminGetListForAuction(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminGetListForAuction$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminGetListForAuction(params: AdminGetListForAuction$Params, context?: HttpContext): Observable<Array<ItemAuctionWithRelations>> {
    return this.adminGetListForAuction$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ItemAuctionWithRelations>>): Array<ItemAuctionWithRelations> => r.body)
    );
  }

  /** Path part for operation `auctionControllerAdminFindById()` */
  static readonly AuctionControllerAdminFindByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFindById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById$Response(params: AdminFindById$Params, context?: HttpContext): Observable<StrictHttpResponse<AuctionWithRelations>> {
    return adminFindById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFindById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFindById(params: AdminFindById$Params, context?: HttpContext): Observable<AuctionWithRelations> {
    return this.adminFindById$Response(params, context).pipe(
      map((r: StrictHttpResponse<AuctionWithRelations>): AuctionWithRelations => r.body)
    );
  }

  /** Path part for operation `auctionControllerReplaceById()` */
  static readonly AuctionControllerReplaceByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `replaceById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById$Response(params: ReplaceById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return replaceById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `replaceById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  replaceById(params: ReplaceById$Params, context?: HttpContext): Observable<any> {
    return this.replaceById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `auctionControllerAdminDeleteById()` */
  static readonly AuctionControllerAdminDeleteByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminDeleteById()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById$Response(params: AdminDeleteById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return adminDeleteById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminDeleteById$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminDeleteById(params: AdminDeleteById$Params, context?: HttpContext): Observable<any> {
    return this.adminDeleteById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `auctionControllerUpdateById()` */
  static readonly AuctionControllerUpdateByIdPath = '/admin/auctions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateById()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById$Response(params: UpdateById$Params, context?: HttpContext): Observable<StrictHttpResponse<any>> {
    return updateById(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateById$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateById(params: UpdateById$Params, context?: HttpContext): Observable<any> {
    return this.updateById$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `auctionControllerAdminFind()` */
  static readonly AuctionControllerAdminFindPath = '/admin/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `adminFind()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind$Response(params?: AdminFind$Params, context?: HttpContext): Observable<StrictHttpResponse<Array<AuctionWithRelations>>> {
    return adminFind(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `adminFind$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  adminFind(params?: AdminFind$Params, context?: HttpContext): Observable<Array<AuctionWithRelations>> {
    return this.adminFind$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<AuctionWithRelations>>): Array<AuctionWithRelations> => r.body)
    );
  }

  /** Path part for operation `auctionControllerCreate()` */
  static readonly AuctionControllerCreatePath = '/admin/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(params?: Create$Params, context?: HttpContext): Observable<StrictHttpResponse<Auction>> {
    return create(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(params?: Create$Params, context?: HttpContext): Observable<Auction> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<Auction>): Auction => r.body)
    );
  }

  /** Path part for operation `auctionControllerUpdateAll()` */
  static readonly AuctionControllerUpdateAllPath = '/admin/auctions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll$Response(params?: UpdateAll$Params, context?: HttpContext): Observable<StrictHttpResponse<LoopbackCount>> {
    return updateAll(this.http, this.rootUrl, params, context);
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateAll(params?: UpdateAll$Params, context?: HttpContext): Observable<LoopbackCount> {
    return this.updateAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<LoopbackCount>): LoopbackCount => r.body)
    );
  }

}
